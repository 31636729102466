<template>
  <ClientEditTemplate v-if="user">
    <ClientEditForm
      :formData="user"
      :editForm="true"
      ref="userForm"
    />
    <div class="btn-gp">
      <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
      <router-link :to="{name: this.$routeName.CLIENT_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
    </div>
  </ClientEditTemplate>
</template>

<script>

import ClientEditForm from '@component/Form/ClientEditForm';
import ClientEditTemplate from "@page/Client/_component/ClientEditTemplate";

export default {
  name: "ClientEdit",
  components: {
    ClientEditForm,
    ClientEditTemplate,
  },
  props: {
    clientId: [String, Number]
  },
  data(){
    return {
      user: false,
      checked: false,
    };
  },
  methods:{
    save() {
      let formData = this.$refs.userForm.formSubmitGetData();
      if(!formData) { return; }
      let sendData = Object.assign({}, formData);

      RequestManager.Client.updateClientById({
        id    : this.clientId,
        user  : sendData
      }).then( (res) => {

        // TODO: fix for user slot
        if(sendData.slotEnable) {

          let data = {
            owner     : res.self_family,
            status    : 'FREE',
            // span      : sendData.span,
            // repeat    : sendData.repeat.id,
            date      : sendData.date,
            start_time: sendData.start_time,
            end_time  : sendData.end_time,
          };

          if(sendData.repeat.id !== 'ONCE') {
            data.repeat = sendData.repeat.id;
            data.span   = sendData.span.id;
          }

          RequestManager.Client.addClientSlot({
            clientId: this.clientId,
            data     : data
          });
        }


        this.$toasts.push({
          message: 'Пользователь успешно обновлен',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.CLIENT_LIST });
      });

    },
  },
  mounted() {
    RequestManager.Client.getClientById({
      id: this.clientId
    }).then((user) => {
      /*
       * about: null
       * avatar: null
       * birthday: null
       * contact_set: []
       * email: "kiryl.konnikov@gmail.com"
       * first_name: "Кирилл"
       * height: null
       * id: 34
       * is_commoner: true
       * is_trainer: false
       * last_name: "Юзер"
       * middle_name: ""
       * mobile: "+79053903860"
       * tags: []
       * trainers: []
       * weight: null
       */
      this.user = user;
    });
  }
};
</script>

<style lang="scss" scoped>

</style>
